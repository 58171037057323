<template>
  <v-sheet
    color="grey"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      type="card, divider, table-heading, card, article"
    ></v-skeleton-loader>
  </v-sheet>
</template>

<script>
// , watch
import router from '@/router'
import { GetIdByPhone } from '@core/api/Restaurant'
import { onMounted, ref } from '@vue/composition-api'
  export default {
    setup() {
    const resN = ref(router.currentRoute.params.restaurantnumber)
    const cusN = ref(router.currentRoute.params.customernumber)
    const RestId = ref(0)
    const RestName = ref('')
    const fetchIdByPhone = resN => {
      console.log('resN',resN)
      GetIdByPhone({ phonenumber: resN})
        .then(response => {
          console.log('resNresponse',response)
          if (response != 0){
          RestId.value = response.Id
          RestName.value = response.Name
          router.push({ name:'api' , params:{ resname: RestName.value , num: cusN.value, id: response.Id }})
          }
          else {
            router.push({name:'error-404'})
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const fetchAndExit = resN => {
      localStorage.removeItem('customerData')
      GetIdByPhone({ phonenumber: resN})
        .then(response => {
          router.push({ name:'AgentBranch' , params:{ name:response.Name, id: response.Id }})
        })
        .catch(error => {
          console.log(error)
        })
    }
    onMounted(() => {
        if (cusN.value.length < 10) {
          fetchAndExit(resN.value) }
          else {
          fetchIdByPhone(resN.value) }
      })
    return {
      resN,
      cusN,

     }
     }
  }
</script>
